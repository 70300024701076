@import '../../utils/Variables.scss';
.header-container {
  width: 100%;
  left: 0;
  height: 60px;
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  background: white;
  box-shadow: 0px 0px 10px $shadowColor;
  z-index: 2;
}

.header-container > .btn-primary,
.header-container button,
.header-container button:hover,
.header-container button:active,
.header-container button:focus {
  background-color: transparent;
  border-color: transparent;
  outline: transparent;
}

.setting-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.uesr-photo {
  width: 36px;
  height: 36px;
  border: 1px solid #5c656e;
  box-sizing: border-box;
  border-radius: 18px;
}

.header-text {
  height: 40px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: $inkBlack;
  flex: none;
  align-self: center;
  margin-bottom: 0px;
  padding-left: 4px;
}

.download-title {
  width: 193px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  height: fit-content;
  max-height: 42px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #404040;
  margin-top: -4px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.download-title-line-1 {
  -webkit-line-clamp: 1;
}

.download-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  align-self: center;
  margin-right: 8px;
}
.download-icon-cursor {
  cursor: pointer;
}

.badge-icon {
  width: 19px;
  height: 19px;
  border-radius: 9.5px;
  background-color: #ff0000;
  padding-top: 2px;
  margin-top: 3px;

  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #ffffff;
}

.system-task-area,
.system-task-area:hover {
  // margin-top: 5px;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  margin-left: 0px;
  cursor: pointer;
}

.system-task-result-container {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  .result-success-item {
    background-color: #dff1ec;
    color: #178768;
    margin-right: 10px;
  }

  .result-failed-item {
    background-color: #fbe1e1;
    color: #d13636;
  }

  .result-common {
    padding: 4px 6px;
    border-radius: 4px;
    font-family: MulishBold;
    font-size: 10px;
    line-height: 13px;
  }
}

.overlay-trigger {
  margin-left: -290px;

  .popover-header {
    border-radius: 30px 30px 0 0;
  }

  .system-task-header {
    width: 360px;
    display: flex;

    // max-width: 600px;
  }

  .popover {
    max-width: 380px;
    background: #ffffff;
    /* Aillia_1.0/black6 */

    border: 1px solid #dadada;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(154, 161, 171, 0.5);
    border-radius: 30px;
  }

  .popover .arrow {
    display: none !important;
  }
  .system-task {
    font-family: Muli;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    margin-top: 7px;
    letter-spacing: 0.5px;

    color: #002148;
  }

  .system-task-content {
    min-height: 68px;
    max-height: 500px;
    overflow-y: scroll;
  }

  .system-task-no-content {
    text-align: center;
    height: 214px;
    align-items: center;
    padding: 50px;
  }

  .system-task-row > div {
    display: flex;
    flex-direction: row;
    height: fit-content;
    max-height: 68px;
    align-items: center;
    width: 100%;
  }

  .system-task-row > div > img {
    align-self: flex-start;
  }

  .system-task-row > div > div {
    align-self: flex-start;
    width: 83px;
  }

  .system-task-row > div > div > div {
    margin-top: 8px;
  }

  .system-task-row {
    display: flex;
    height: 69px;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .system-task-row-first-section {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .system-task-row-status {
    width: 150px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .system-task-row-status-downloading {
    display: block;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0px;
  }

  .system-task-row-status-label {
    font-size: 10px;
    line-height: 13px;
  }

  .system-task-row-border {
    height: 1px;
    background: #dadada;
    width: 100%;
  }

  .download-cancel-button {
    margin-left: 0px;
    font-size: 10px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #d89616;
    margin-top: 9px;
  }

  .download-button {
    color: #178768;
  }
}

.margin-left-auto {
  margin-left: auto !important;
}

.header-user-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.header-user-area :hover {
  cursor: pointer;
}

.header-user-area-photo-area {
  width: 36px;
  height: 36px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-user-area-logout-dropdown {
  width: 220px;
  height: 87px;
  position: absolute;
  top: 34px;
  left: -92px;
  transition-duration: 0.1s;
  z-index: 5;
}

.header-user-area-logout-dropdown-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-user-area-logout-image {
  object-fit: contain;
}

.header-user-area-logout-dropdown-content {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.header-user-area-logout-text {
  font-family: MuliExtraBold;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  color: #404040;
  margin-bottom: 0px;
  margin-top: 10px;
}
