.customer-detail-edit-title-with-button {
    display: flex;
    margin-top: 32px;
    align-items: center;
}

.customer-detail-section-title-container {
    margin-top: 0;
}

.customer-detail-edit-title {
    margin-bottom: 0;
}

.customer-detail-section-title {
    font-family: MuliBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    color: #002148;
}

.customer-detail-edit-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
}

.customer-detail-edit-button {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 8px 16px;
    height: auto;
}

.customer-detail-edit-button-second {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 16px;
    margin-right: 0;
    padding: 8px 16px;
    height: auto;
}

.customer-detail-edit-image {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.customer-detail-button-label {
    margin-bottom: 0;
    cursor: pointer;
}


.customer-detail-status {
    margin-bottom: 0;
}

.customer-detail-level {
    margin-left: 8px;
    margin-bottom: 0;
    background: #EEF1FB;
    color: #4E8DD7;
}

.customer-detail-tips-container {
    margin-top: 16px;
}

.customer-detail-tips {
    margin-left: 8px !important;
    margin-top: -30px !important;
    max-width: 700px !important;
}

.customer-detail-description {
    margin-top: 16px;
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
}


.customer-detail-info-container {
    margin-top: 16px;
}

.customer-detail-info-title {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #4D5560;
}

.customer-detail-info-content {
    margin-left: 8px;
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.customer-detail-show-more-button {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 0;
    margin-right: auto;
    padding: 8px 16px;
    height: auto;
}

.customer-detail-separate {
    margin-top: 30px;
    margin-bottom: 14px;
    width: '100%';
    height: 1px;
    background-color: #DADADA;
}

.customer-detail-points-container {
    display: flex;
    margin-top: 16px;
}

.customer-detail-point-status-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 24px;
    padding: 16px;
    background-color: #EEF1FB;
}

.customer-detail-points-container:last-child {
    margin-right: 0;
}

.customer-detail-point-status-value {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #4E8DD7;
}


.customer-detail-point-status-content {
    margin-top: 8px;
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.customer-detail-tag-container {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
}

.customer-detail-single-tag {
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #EEEEEE;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color:#4D5560;
}

.customer-detail-single-remark-container {
    margin-top: 16px;
    width: 100%;
}

.customer-detail-single-remark-title {
    display: flex;
    align-items: center;
}

.customer-detail-single-remark-admin-name {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #4D5560;
}

.customer-detail-single-remark-creation-date {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8C8C8C;
}

.customer-detail-delete-remark-image {
    width: 27px;
    height: 27px;
    margin-left: 16px;
    margin-right: 0;
    cursor: pointer;
}

.customer-detail-single-remark-content {
    margin-top: 8px;
    padding: 12px;
    border-radius: 8px;
    background-color: #EEEEEE;
}

.customer-detail-single-remark-remark {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.customer-detail-prompt {
    padding: 24px 30px;
    width: 800px;
}

.customer-detail-prompt-customer-group {
    padding: 24px 30px;
    width: 850px;
}

.customer-detail-modal-title {
    margin-bottom: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    color: #4D5560;
}

.customer-detail-prompt-field-title {
    margin-top: 24px;
}

.customer-detail-prompt-buttons {
    margin-top: 24px;
}

.customer-detail-prompt-selector {
    width: 250px;
}
