@import './src/utils/Variables.scss';

.customer-additional-data {
  width: 800px;
  .help-message {
    margin-top: 0;
    margin-bottom: 0;
    width: 60px;
  }
  .custom-markdown-area-title.custom-markdown-area-title-short {
    width: auto;
    flex: 1;
  }
}
