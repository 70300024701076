.message-credit-card {
  min-height: 231px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;
  margin: 30px 40px 40px 40px;
  padding: 30px;
}
.message-credit-card-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.message-credit-card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: #8c8c8c;
}
.message-credit-card-title {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 900;
  line-height: 22.59px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #002148;
}
.message-credit-spent-section {
  width: 100%;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 24px;

  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  line-height: 30.12px;
  text-align: left;

  color: #404040;
}
.message-credit-date-range {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: left;
  color: #4d5560;
}
