.reset-button,
.reset-button:visited,
.reset-button:hover,
.reset-button:active,
.reset-button:focus {
  border: none;
  background: transparent;
  border-color: transparent;
  outline: none !important;
}

.reset-button {
  margin-left: 20px;
}

.custom-font {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.dropdown-section {
  width: 250px;
  margin-right: 15px;
}

.custom-number-input-long {
  width: 100px;
  margin-right: 10px;
}

.content-area {
  border: 1px dashed #c2c2c2;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 39px;
  padding: 0px 30px 31px 30px;
}

.content-add-button {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #4e8dd7;

  padding: 6px 16px;
  height: 34px;

  background: #ffffff;
  border: 2px solid #4e8dd7;
  box-sizing: border-box;
  border-radius: 100px;
  margin-top: 30px;
}

.content-add-button:visited,
.content-add-button:hover,
.content-add-button:active,
.content-add-button:focus {
  // border: 2px solid #4e8dd7;
  // background: transparent;
  // border-color: transparent;
  // outline: none !important;
  color: #4e8dd7;
  padding: 6px 16px;
  height: 34px;
  font-size: 14px;
  line-height: 18px;
}

.content-add-button-selection-area {
  padding: 18px 0px 18px 18px;
  // margin: 8px 0px 10px 0px;
  width: 440px;
  max-height: 300px;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 161, 171, 0.3);
  border-radius: 8px;

  // position: absolute;
  // z-index: 100;
}

.content-add-button-selection-filter {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-right: 18px;
}

.content-add-button-selection-section-area {
  max-height: 227px;
  overflow-y: scroll;
  margin-top: 16px;
}

.content-add-button-selection-section {
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;

  color: #404040;

  display: flex;
  margin-top: 15px;
}

.content-add-button-selection-section-area ul {
  margin-block-end: 0px;
  padding-inline-start: 0px;
}

.content-add-button-selection-section-area .li-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;

  color: #404040;

  cursor: pointer;
}

.content-add-button-selection-section-area .li-icon {
  width: 4px;
  height: 4px;
  background: #404040;
  border-radius: 2px;
  margin: 18px 13px 0px 0px;
}

.content-delete-button-area {
  margin-left: auto;
  margin-top: 30px;

  width: 27px;
  height: 27px;
  position: absolute;
  right: 0;
}

.content-delete-button {
  width: 27px;
  height: 27px;
}

.content-condition-area {
  text-align: center;
}

.content-condition-or-area {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.content-condition-area label {
  margin-top: -14px;
  position: absolute;
  background: #fff;
  padding: 0px 20px;
  // width: 37px;

  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;

  color: #404040;
}

.content-condition-dash {
  height: 1px;
  border: dashed 0.5px #ccc;
  margin-top: 15px;
}

.content-condition-solid {
  height: 2px;
  border: solid 0.5px #ccc;
  margin-top: 15px;
}

.margin-left-zero {
  margin-left: 0px;
}

.search_bar_icon {
  left: 27px;
}

.content-campaign-related-to {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #404042;

  /* Inside Auto Layout */

  flex: none;
  // order: 1;
  align-self: center;
  // flex-grow: 0;
  margin: 8px 10px 8px 0px;
}

.font-weight {
  font-weight: 800;
}

.content-campaign-branchLine {
  width: 20px;
  height: 68px;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dropdown-menu {
  border-width: 0px;
}

.dropdown-item {
  padding: 0px;

  a:active {
    background-color: #ffffff;
  }
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.product-selector-search-button {
  display: flex;
  color: #8c8c8c;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  outline: none !important;
  background-color: #fff;
  box-sizing: border-box;
  width: 604px;
  height: 40px;
  margin: 5px 0px;
}

.product-selector-search-button-icon {
  position: initial;
  margin-left: 10px;
}

.product-selector-search-button-text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #8c8c8c;

  // padding-top: 5px;

  cursor: pointer;
  text-decoration: none !important;
}

// .product-selector-search-button:visited {
//   text-decoration: none !important;
//   outline: none !important;
// }

.product-selector-popup {
  width: 691px;
  // height: 713px;
}

.product-selector-popup-searchbar {
  margin-top: 21px;
  width: 500px;
}

// .product-selector-popup-searchbar input {
//   padding-left: 0px;
// }

.product-selector-popup-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: initial;
  margin-top: 15px;
}

.product-selector-checkbox-container {
  margin-top: 0px;
}

.product-selector-checkmark {
  top: 16px;
}

.product-selector-image {
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;

  width: 44px;
  height: 44px;

  margin: 0px 5px;
}

.product-selector-search-delete {
  // width: 12px;
  // height: 12px;
  /* margin: 16px 5px; */
  /* padding: 16px; */
  margin-left: auto;
  margin-top: 16px;
  cursor: pointer;

  background-color: transparent;
  border: 0px;
  outline: none !important;
}

.product-selector-search-icon {
  left: 49px;
}

.dropdown-toggle::after {
  content: none;
}

.width-400px {
  width: 400px;
}