.filter-area {
  // display: flex;
  // flex-direction: column;
  display: flex;

  width: 351px;
  position: fixed;
  // position: -webkit-sticky;
  // position: sticky;
  right: 0;
  margin-left: auto;
  top: 60px;
  background: #ffffff;
  border: 2px solid #f5f6fa;
  box-sizing: border-box;
  padding: 45px;
  z-index: 3;
  height: auto;
  min-height: calc((100vh - 60px));
  max-height: calc((100vh - 60px));

  // display: -moz-box;
  // display: -webkit-box;
  // display: box;
  overflow-y: scroll;

  // .filter-checkbox {
  //   border: 1px solid #4e8dd7;
  //   box-sizing: border-box;
  //   border-radius: 2px;
  //   margin-right: 5px;
  // }

  .multiple-dropdown-container {
    width: 100%;
  }

  .custom-create-section-label {
    width: 175px;
  }

  .btn.reset-selected {
    margin-top: 24px;
    position: absolute;
  }

  .store-list-item-container {
    width: 220px;
  }

  .store-list-item {
    width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .custom-title-dropdown {
    margin-right: 0;
    .filterable-search-bar-input {
      font-size: 14px;
    }
    .checkbox-container {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .filter-menu-border {
    background: #c2c2c2;
    height: 1px;
    margin: 5px -2px;
    display: inline-table;
    width: -webkit-fill-available;
  }

  // .btn-primary {
  //   color: #979797;
  //   background-color: #fff;
  //   border-color: transparent;
  // }

  .filter-button-group {
    .btn-primary {
      color: #979797;
      background-color: #fff;
      border-color: #979797;
    }

    .filter-button-group-item {
      width: 50%;
      height: 40px;
    }

    .filter-button-group-item,
    .filter-button-group-item:left {
      background-color: #fff;
      border-color: #979797;
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      height: 40px;
      color: #979797;
    }

    // .filter-button-group-item:active,
    // .filter-button-group-item:focus
    .filter-button-selectd,
    .filter-button-group-item:hover {
      background: #ebf2fc;
      border: 1.5px solid #4e8dd7;
      box-sizing: border-box;
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 131% */

      height: 40px;
      color: #4e8dd7;
    }

    .filter-button-selectd-0 {
      border-radius: 5px 0 0 5px;
    }

    .filter-button-selectd-1 {
      border-radius: 0 5px 5px 0;
    }
  }

  .filter-slider {
    color: #4e8dd7;
  }

  .filter-reset-button {
    margin-left: auto;
    border-bottom: 1px solid #979797;
    color: #979797;
    margin-top: 30px;
    margin-bottom: 22px;
  }

  .filter-reset-button,
  .filter-reset-button:visited,
  .filter-reset-button:hover,
  .filter-reset-button:active,
  .filter-reset-button:focus {
    border: none;
    background: transparent;
    border-color: transparent;
    outline: none !important;
    color: #979797;
    border-bottom: 1px solid #979797;
  }

  .filter-list-items-area {
    height: auto;
    min-height: 120px;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 15px;
    margin-top: 15px;

    &.extendable-filter-items-single-choice {
      min-height: auto;
    }
  }

  .filter-list-items-sub-area {
    padding-left: 25px;
    max-height: initial;
    min-height: initial;
    overflow-y: initial;
  }

  .filter-list-items-has-sub-area-height {
    max-height: 300px;
    // height: 300px;
    height: auto;
    overflow-y: visible;
    overflow-x: initial;
    min-height: auto;
  }

  .fit-content-height-area {
    min-height: fit-content;
    overflow: initial;
  }
}

.filter-open-button-margin {
  margin-left: auto;
  margin-top: auto;

  .btn-primary {
    color: #979797;
    background-color: #fff;
    border-color: transparent;
  }
}

.filter-checkbox {
  width: 25px;
  height: 25px;
  padding: 0px;
}

.checkbox-container {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;

  display: block;
  position: relative;
  padding-left: 22px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 21px;
}

.checkbox-container-no-margin {
  margin-top: 0px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid #4e8dd7;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #4e8dd7;
  // background: #ffffff;
  // border: 0.6px solid #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  // background: #ffffff;
  // border: 0.6px solid #ffffff;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 3px;
  top: 0px;
  width: 4.5px;
  height: 8px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.display-flex-area {
  display: flex;
  position: relative;
}

.filter-age-min {
}

.filter-age-max {
  margin-left: auto;
}

.filter-age-font {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #4d5560;
}

.range_date_picker {
  width: auto;
}

.custom-date-picker {
  // position: fixed;
}

.filter_choice_title_area {
  display: flex;
  margin-top: 30px;
}

.filter_choice_title {
  margin-top: initial;
}

// div.ant-picker-dropdown-range {
//   parent {
//     position: fixed;
//   }
// }
.radio-select-button {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
}
