@import '../../../utils/Variables.scss';
.display-over-all-limit-container {
  display: flex;
  flex-direction: row;
}
.display-over-all-limit {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
}

.display-over-all-limit-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.profile-section-label-bottom {
  margin-bottom: 0px;
  word-break: break-word;
}
