@import '../../../utils/ColorVariables.scss';
.btn-download {
  margin-right: 10px;
}
.export-report-modal {
  width: 800px;
  max-width: calc(100vw - 60px);
  min-width: 300px;
  .base-prompt-other-body {
    margin-top: 6px;
    .d-flex {
      gap: 40px;
    }
  }
}
.import-customer-modal {
  width: 800px;
  max-width: calc(100vw - 60px);
  min-width: 300px;
  padding: 24px 30px;

  .upload-customer-btn {
    padding: 0 16px;
    font-size: 14px;
    line-height: 17.5px;
    height: 34px;
  }
  .create-section-label-bottom-space {
    margin-top: 24px;
  }
  .uploaded-file-name {
    margin-bottom: 8px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #4D5560;
  }

  textarea {
    height: 120px;
  }
}

.width-400 {
  width: 400px;
}
.import-button-with-dropdown {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    height: 12px;
    width: 12px;
  }
}
.import-customer-dropdown {
  position: absolute;
  min-width: 230px;
  min-height: 104px;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #4e8dd7;
  box-shadow: 0px 0px 10px 0px #9aa1ab80;
  z-index: 3;
  top: 50px;
  right: 0px;
  cursor: auto;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;

  > div {
    cursor: pointer;
  }
}
