.current-msg-credit-card {
  margin-top: 30px;
  padding: 24px 16px;
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: #4e8dd7;

  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  .card-subtitle {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.08px;
    text-align: left;
  }

  .credit-to-be-spent {
    font-family: Mulish;
    font-size: 24px;
    font-weight: 800;
    line-height: 30.12px;
    text-align: left;
  }
}
